<script setup lang="ts">
import type { WithClassAsProps } from './interface';
import { cn } from '@laam/lib/utils';
import { useCarousel } from './useCarousel';
import { PhCaretLeft } from '@phosphor-icons/vue';
import { IconButton } from '../icon-button';

const props = defineProps<WithClassAsProps>();

const { orientation, canScrollPrev, scrollPrev } = useCarousel();
</script>

<template>
	<IconButton
		v-if="canScrollPrev"
		:class="
			cn(
				'hidden lg:flex touch-manipulation absolute size-9xl !rounded-full p-0 bg-white transition-all',
				'disabled:!bg-gray-50 disabled:opacity-50 disabled:!cursor-not-allowed disabled:!pointer-events-none disabled:shadow-none',
				orientation === 'horizontal'
					? 'left-2xl top-1/2 -translate-y-1/2'
					: 'bottom-2xl left-1/2 -translate-x-1/2 rotate-90',
				props.class,
			)
		"
		variant="icon"
		@click.stop="scrollPrev"
		@click.prevent="scrollPrev"
	>
		<slot>
			<PhCaretLeft class="size-7xl text-current" />
			<span class="sr-only">Previous Slide</span>
		</slot>
	</IconButton>
</template>
